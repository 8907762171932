import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { Dispatch, STORE } from '../../_redux/types';
import { allConstants } from '../../_redux/_constants';
import { initializeAnalytics } from "../../helpers/analytics";


import "./style.less";

const Login_ForgotPass = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const {loginPageType} = useSelector((state: STORE) => state.appData);

  useEffect(() => {
    initializeAnalytics();
  }, []);

  
  function setPageType(value) {
    dispatch({
      type: allConstants.SET_KEY,
      payload: { key1: "loginPageType", value},
    });
  }

  let pageContent;

  if (loginPageType === 'forgotPassword' || searchParams.get("params")) {
    pageContent = (
      <>
        <div className="left-side">
          <ForgotPassword pageNum={searchParams.get("params") ? 2 : 0} go_to_login={() => setPageType('login')} />
        </div>
        <div className="right-side">
          <div className="welcome-text-could">
            One place to manage
            <br />
            trucking life
          </div>
          <div className="subtitle">Join network of professional truckers</div>
        </div>
      </>
    );
  } else if (loginPageType === 'initial') {
    pageContent = (
      <div className="split-screen-container">
        <div className="half-screen white-background">
          <button
            className="centered-button button-company"
            onClick={() => setPageType('login')}
          >
            Trucking Company
          </button>
        </div>
        <div className="half-screen blue-background">
          <button
            className="centered-button button-driver"
            // onClick={ () => {return window.location.href = "/dwf";}}
            onClick={ () => {return window.location.href = "/dwf/#/registration";}}

          >
            Driver
          </button>
          <div className="logo-white">
            <a href={window.location.origin}>
              <img className="layer" alt="Layer" src="/img/Logo_№1_2x.png" />
            </a>
          </div>
        </div>
      </div>
    );
  } else if (loginPageType === 'login') {
    pageContent = (
      <>
        <div className="left-side">
          <Login go_to_forgot={() => setPageType('forgotPassword')} />
        </div>
        <div className="right-side">
          <div className="welcome-text-could">
            One place to manage
            <br />
            trucking life
          </div>
          <div className="subtitle">Join network of professional truckers</div>
        </div>
      </>
    );
  }

  return (
    <div className="authContainer">
      <div className="login p-3">
        {pageContent}
      <div className="logo-gark">
        <a href={window.location.origin}>
          <img className="layer" alt="Layer" src="/img/Logo_Dark.png" />
        </a>
      </div>
      </div>
    </div>
  );
};

export { Login_ForgotPass };
