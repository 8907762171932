import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Logo } from '../../icons/Logo';
import { UserImagesUser } from '../UserImagesUser';
import { DropDownMenu } from '../DropDownMenu';
import { HelpDeskButton } from '../HelpDeskButton';
import { ButtonNotification } from '../ButtonNotification';
import { Dispatch, STORE } from '../../_redux/types';
import { SERVER_URL } from '../../_redux/_requests';
import { handleCopy } from "../../helpers/clipboardHelper";

import './style.less';
// @ts-ignore
import sprite from "../../icons/sprite.svg";


export const Header = ({ icon = <Logo className="logo-instance" /> }) => {
  const [copied, setCopied] = useState(false);

  const { reg_id, account, company_information } = useSelector( (state: STORE) => state.appData,);
  const legalName = company_information.Legal_Name.value;

  const fullname = useMemo(() => {
    let fullName = account.first_name + ' ' + account.last_name;
    if (reg_id == company_information.company_id) fullName = company_information.First_Name.value + ' ' + company_information.Last_Name.value;
    return fullName;
  }, [account]);

  const newApplicationsCount = 3;
  const line = <img className="line" alt="Line" src={'/img/line-5-1.svg'} />;
  // const url = `${SERVER_URL}/dwf/apply-to-company/?company=${company_information.company_id}`;
  const url = `${SERVER_URL}/dwf/apply-to-company/?company=${company_information.company_id}#/registration`;


  const copyToClipboard = () => {
    handleCopy(url);
        // стан скопійовано 
        setCopied(true);

        // повертаємо кнопку через 1.5с
        setTimeout(() => {
          setCopied(false);
        }, 1500);
  };

  return (
    <div className="header">
      <div className="gap-32 align-items-center">
        <a href={window.location.origin}>
          {icon}
        </a>
        <span className="font-w-600  legal-name">{legalName}</span>
        <div className="link-wrap">
          <div className="font-w-600 legal-name-link"><span className="driver-application">Online Driver Application:</span></div>
          <button className="header-copy-link" onClick={copyToClipboard}> Copy {copied && (
            <svg className="icon-check" width="14" height="14" style={{ marginLeft: '8px' }}>
              <use href={sprite + "#icon-check"} />
            </svg>)}
          </button>
          <a
            className="color-primary font-w-600"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {url}
          </a>
        </div>
      </div>
      <div className="gap-8 flex-grow-1 align-items-center justify-content-end">
        {/* <ButtonNotification count={newApplicationsCount} /> 
        {line} */}
        <HelpDeskButton />
        {line}
        <div className="user-name-btn">
          <UserImagesUser userName={fullname || 'Empty'} />
          <DropDownMenu />
        </div>
      </div>
    </div>
  );
};
