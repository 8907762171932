import React, { useEffect } from "react";

import "./style.less";

import { HeaderApplicants } from "../../components/HeaderApplicants";
import { ApplicantsTab } from "../../components/ApplicantsTab/ApplicantsTab";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { STORE } from "../../_redux/types";

export const ApplicantScreen = () => {
  const { applicantId } = useParams();
  const normalizedId = applicantId?.replace(":", ""); // Убираем двоеточие, если оно есть

  const { applications } = useSelector((state: STORE) => state.appData);

  const applicant = applications?.find(
    (applicant) => String(applicant.id) === normalizedId
  );

  return (
    <div className="p-3 screen-body">
      <div className="h-100">
        <HeaderApplicants
          name={"Applicants"}
          info={applicant ? `${applicant.driver_first_name} ${applicant.driver_last_name}` : "Applicant"}
        />
        <ApplicantsTab />
      </div>
    </div>
  );
};
