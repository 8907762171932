import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderApplicantsList } from "../../components/HeaderApplicantsList";
import { ApplicantsListTable } from "../../components/ApplicantsListTable";
import { SegmentedButton } from "../../components/SegmentedButton";
import { TableReject } from "../../components/TableReject/TableReject";
import { Dispatch, STORE } from "../../_redux/types";
import { allActions } from "../../_redux/_actions";
import { allConstants } from "../../_redux/_constants/index";

import "./style.less";

// filters
const ApplicantsTypes = [
  {
    id: "active",
    text: "Active",
    isActive: true,
  },
  {
    id: "draft",
    text: "Draft",
    isActive: false,
  },
  {
    id: "rejected",
    text: "Rejected",
    isActive: false,
  },
];

export const ApplicantsListScreen = () => {
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(ApplicantsTypes);
  const applications = useSelector((state: STORE) => state.appData.applications);
  const { company_id } = useSelector((state: STORE) => state.appData.company_information);
  const { application_statuses } = useSelector((state: STORE) => state.appData.dictionaries);

  const [filteredApplications, setFilteredApplications] = useState(applications);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]); 

  useEffect(() => {
    (async () => {
      await applications_get_by_company();
    })();
  }, [company_id]);

  useEffect(() => {
    const sortedApplications = [...applications].sort((a, b) => {
      const dateA = new Date(a.application_create_time).getTime();
      const dateB = new Date(b.application_create_time).getTime();
      return dateB - dateA; // Новые заявки первыми
    });

    setFilteredApplications(sortedApplications);
  }, [applications]);

  //Функція вибору статусу за лейблом 
  const handleStatusSelect = (statusLabel: string) => {
    setSelectedStatuses((prevStatuses) =>
      prevStatuses.includes(statusLabel)
        ? prevStatuses.filter((status) => status !== statusLabel) //Прибираємо статус, якщо його вже обрано
        : [...prevStatuses, statusLabel] // додаємо якщо не вибран
    );
  };

  // Активні заявки, фільтрація за масивом статусів
  const activeList = useMemo(() => {
    return filteredApplications.filter((appl) => {
      const stdata = application_statuses.find((st) => st.dict_id === appl?.applic_status);
      const isActive =
        stdata?.label?.toLowerCase().includes("new") ||
        stdata?.label?.toLowerCase().includes("pending") ||
        stdata?.label?.toLowerCase().includes("hiring") ||
        stdata?.label?.toLowerCase().includes("training");

      // перевіряємо, якщо хоча б один із вибраних статусів збігається
      return (
        isActive &&
        (selectedStatuses.length === 0 ||
          selectedStatuses.some((status) =>
            stdata?.label?.toLowerCase().includes(status.toLowerCase())
          ))
      );
    });
  }, [filteredApplications, application_statuses, selectedStatuses]);

  const draftList = useMemo(() => {
    return filteredApplications.filter((appl) => !appl?.applic_status);
  }, [filteredApplications]);

  const rejectedList = useMemo(() => {
    return filteredApplications.filter((appl) => {
      const stdata = application_statuses.find((st) => st.dict_id === appl?.applic_status);
      return stdata?.label?.toLowerCase().includes("reject");
    });
  }, [filteredApplications, application_statuses]);

  const activeTab = useMemo(() => {
    return tabs.find((tab) => tab.isActive)?.id;
  }, [tabs]);

  const applications_get_by_company = async () => {
    return await dispatch(
      allActions.loadAndSetInRedux(allConstants.LOAD_APLICATIONS_SUCCESS, {
        action: "applications_get_by_company",
        company_id: company_id,
      })
    );
  };

  function selectTab(id) {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        isActive: id === tab.id && !tab.isActive,
      }))
    );
  }

  return (
    <div className="p-3">
      <div className="body screen-border">
        <HeaderApplicantsList
          pageName={"Applicants"}
          segmentedButton={<SegmentedButton btns={tabs} selectTab={selectTab} />}
          actionsBlock={null}
          onSearch={setFilteredApplications}
          data={applications}
          keys={["driver_first_name", "driver_last_name", "email", "phone_number"]}
          onSelectStatus={handleStatusSelect}
          selectedStatus={selectedStatuses} 
          isActiveTab={activeTab === "active"}
        />
        {activeTab === "rejected" ? (
          <TableReject drivers={rejectedList} />
        ) : (
          <ApplicantsListTable drivers={activeTab === "active" ? activeList : draftList} />
        )}
      </div>
    </div>
  );
};
